@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0%;
    padding: 0%;
    font-family: 'Roboto Slab', serif;
    box-sizing: border-box;
    text-decoration: none;
    scroll-behavior: smooth;
    
}